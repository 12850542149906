import React from 'react'
import { useTranslation } from 'react-i18next'
import { dateFormatter } from '../../utils/general.utils'
import Link from '../Link'

interface IIntegrationListItemProps {
  name: string
  id: string
  registrationDate: string[]
}

const IntegrationListItem: React.FC<IIntegrationListItemProps> = ({
  name,
  id,
  registrationDate,
}) => {
  const { t } = useTranslation()
  const formattedDate = dateFormatter(Number(registrationDate[0]))

  return (
    <li className="integration-list-item">
      <Link
        className="link-wrapper"
        to={`/my-apps/${id}`}
        state={{ clientId: id }}
        data-cy={`integrations-list-item-${id}`}
      >
        <div className="list-item-content">
          <div className="content-left">
            <h3>{name}</h3>
            <p>{`${t('integrations.created')} ${formattedDate}`}</p>
          </div>
          <div className="content-right">
            <span className="md-badge md-badge--pastel">
              {t('integrations.integration')}
            </span>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default IntegrationListItem
