import React from 'react'
import { useTranslation } from 'react-i18next'
import Integration from '../../components/Integration'
import { PAGE_VIEWED } from '../../constants/metrics'
import MetricsService from '../../services/metricsService'

interface ICreateIntegrationProps {
  hasCurrentIntegrations: boolean
}
const CreateIntegration: React.FC<ICreateIntegrationProps> = ({
  hasCurrentIntegrations,
}) => {
  const { t } = useTranslation()
  const metricsService = MetricsService.getInstance()

  metricsService.track(PAGE_VIEWED, {
    pageName: 'Create Integrations',
  })

  return (
    <section className="create-integration">
      <div>
        <h2>{t('integrations.newIntegration')}</h2>
      </div>
      <Integration hasCurrentIntegrations={hasCurrentIntegrations} />
    </section>
  )
}

export default CreateIntegration
