import { Button } from '@momentum-ui/react'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IEditButtonsProps {
  show: boolean
  handleCancel: Function
  handleSave: Function
  isLoading?: boolean
  existingIntegration?: boolean
  disableSave?: boolean
}

const EditButtons: React.FC<IEditButtonsProps> = ({
  show,
  handleCancel,
  handleSave,
  isLoading,
  existingIntegration,
  disableSave,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {show && (
        <div className="edit-buttons-container">
          <Button
            id="integration-cancel-button"
            ariaLabel={t('integrations.cancel')}
            onClick={handleCancel}
            role="button"
            data-cy="cancel-integration-btn"
          >
            {t('integrations.cancel')}
          </Button>
          <Button
            className={disableSave ? 'disabled' : ''}
            id="integration-submit-button"
            ariaLabel={
              existingIntegration
                ? t('actionButtons.save')
                : t('integrations.addIntegration')
            }
            type="submit"
            color="blue"
            onClick={handleSave}
            loading={isLoading}
            data-cy="submit-integration-btn"
          >
            {existingIntegration
              ? t('actionButtons.save')
              : t('integrations.addIntegration')}
          </Button>
        </div>
      )}
    </>
  )
}

export default EditButtons
